

import { Vue, Component } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { pInteger } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
@Component({
  name: 'Inventory',
  components: { UploadFile }
})
export default class Inventory extends Vue {
  tabIndex = '0'
  dialogVisible = false

  page = 1
  size = 10
  total = 0
  items = []

  assetTypeTree = []
  areaList = []
  userList = []

  searchForm = {
    inventoryState: '', // 盘点状态1未盘2已盘3盘亏4盘盈
    assetType: '', // 资产分类
    assetCodeName: '' // 资产编码、名称,模糊查询
  }

  details = {
    orderId: '', // 单据ID
    orderNumber: '', // 单号
    orderType: '', // 单据类型1入库单2领用单3退还单4调拨单5盘库单6处置单
    orderCount: '', // 全部总数
    notInventoryCount: '', // 未盘数量
    endInventoryCount: '', // 已盘数量
    lossInventoryCount: '', // 盘亏数量
    profitInventoryCount: '' // 盘盈数量
  }

  formData = {
    orderId: '', // 单据ID
    assetName: '', // 资产名称
    assetType: '', // 资产类型id
    assetState: '', // 资产状态1空闲2在用3调拨4废弃
    brand: '', // 品牌
    model: '', // 型号
    equipmentSerialNumber: '', // 设备序列号
    custodianId: '', // 保管员
    projectId: '', // 所属项目
    locationId: '', // 存放位置
    purchaseTime: '', // 购置时间
    assetRight: '',
    purchaseAmount: '', // 购置金额（含税）
    warehousingTime: '', // 入库时间
    expectedServiceLife: '', // 预计使用期限（月）
    remarks: '', // 备注
    userId: '', // 操作人id
    requisitionDate: '', // 领用日期
    fileList: [] // 附件列表
  }

  private validtor999 = (rule: any, value: string, callback: Function) => {
    if (+value > 999) {
      return callback(new Error('请输入1-999的数字'))
    }
    return callback()
  }

  private validtor99999 = (rule: any, value: string, callback: Function) => {
    const reg = /^(0|[1-9][0-9]*)(\.\d+)?$/
    if (value) {
      if (!reg.test(value) || Number(value) > 999999999.99) {
        return callback(new Error('请输入0到999999999.99的数'))
      }
    }
    return callback()
  }

  rules = {
    brand: [
      { required: true, message: '请输入品牌', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    assetName: [
      { required: true, message: '请输入资产名称', trigger: ['blur', 'change'] }
    ],
    custodianId: [
      { required: true, message: '请输入保管员', trigger: ['blur', 'change'] }
    ],
    assetRight: [
      { required: true, message: '请选择产权所属', trigger: ['blur', 'change'] }
    ],
    assetType: [
      { required: true, message: '请输入资产分类', trigger: ['blur', 'change'] }
    ],
    locationId: [
      { required: true, message: '请输入存放位置', trigger: ['blur', 'change'] }
    ],
    model: [
      { required: true, message: '请输入型号', trigger: ['blur', 'change'] }
    ],
    purchaseTime: [
      { required: true, message: '请选择购置时间', trigger: ['blur', 'change'] }
    ],
    purchaseAmount: [
      { required: true, message: '请选择购置金额（含税）', trigger: ['blur', 'change'] },
      { validator: this.validtor99999, trigger: ['blur', 'change'] }
    ],
    expectedServiceLife: [
      { validator: pInteger, trigger: ['blur', 'change'] },
      { validator: this.validtor999, trigger: ['blur', 'change'] }
    ],
    fileList: [
      { required: true, message: '请选择资产图片', trigger: ['blur', 'change'] }
    ]
  }

  cascaderProps = {
    children: 'childNode',
    label: 'assetLocationName',
    value: 'assetLocation',
    checkStrictly: true,
    emitPath: false
  }

  pickerOptions = {
    disabledDate: (time: Date) => {
      // 大于当前日期禁止选择
      return time.getTime() > Date.now()
    }
  }

  private equityList = []

  get orderId () {
    return this.$route.query.orderId
  }

  get projectName () {
    return this.$route.query.projectName
  }

  get projectId () {
    return this.$route.query.projectId
  }

  get detail () {
    return this.$route.query.detail
  }

  created () {
    this.formData.orderId = this.orderId as string
    this.formData.projectId = this.projectId as string
    this.loadData()
    this.loadAssets()
    this.loadAssetTypeTree()
    this.loadAreaList()
    this.loadUserList()
    this.getEquityList()
  }

  loadData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetInventoryByOrderId, {
      orderId: this.orderId
    }).then(res => {
      this.details = res
    })
  }

  loadAssets () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetImageByPage, {
      orderId: this.orderId,
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.items = res.list
      this.total = res.total
    })
  }

  // 查询资产信息
  getEquityList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetRightByList).then((res) => {
      this.equityList = res.list || []
    })
  }

  tabClick (instance: any) {
    if (instance.name === '0') {
      this.searchForm.inventoryState = ''
    } else {
      this.searchForm.inventoryState = instance.name
    }
    this.onSearch()
  }

  loadAssetTypeTree () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList).then(res => {
      this.assetTypeTree = res
    })
  }

  loadAreaList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList, {
      level: 2
    }).then(res => {
      this.areaList = res || []
    })
  }

  loadUserList () {
    this.$axios.get(this.$apis.scenarios.selectUserListByList).then(res => {
      this.userList = res.list || []
    })
  }

  getInventoryStateName (inventoryState: string) {
    switch (inventoryState) {
      case '1': return '未盘'
      case '2': return '已盘'
      case '3': return '盘亏'
      case '4': return '盘盈'
      default: return '未知'
    }
  }

  getAssetStateName (assetState: string) {
    switch (assetState) {
      case '1': return '空闲'
      case '2': return '在用'
      case '3': return '调拨'
      case '4': return '废弃'
      default: return '未知'
    }
  }

  onSearch () {
    this.page = 1
    this.$nextTick(() => {
      (this.$refs.table as any).clearSelection()
    })
    this.loadData()
    this.loadAssets()
  }

  onSuccess (data: any) {
    (this.formData.fileList as any).push(data)
  }

  imgRemove (index: number) {
    this.formData.fileList.splice(index, 1)
  }

  endInventory () {
    if (parseInt(this.details.notInventoryCount) > 0) {
      this.$confirm('存在未盘的资产，是否继续盘点？', '提示').then(() => {
        this.$axios.post(this.$apis.assetScheduling.endAssetInventory, {
          orderId: this.orderId
        }).then(() => {
          this.$router.back()
        })
      })
    } else {
      this.$axios.post(this.$apis.assetScheduling.endAssetInventory, {
        orderId: this.orderId
      }).then(() => {
        this.$router.back()
      })
    }
  }

  showDialog () {
    this.dialogVisible = true
    this.$nextTick(() => {
      (this.$refs.form as ElForm).resetFields()
    })
  }

  delInventoryAsset (id: any) {
    let idList: any = []
    if (!id) {
      idList = (this.$refs.table as any).selection.map((item: any) => item.id)
    } else {
      idList = [id]
    }
    if (!idList.length) {
      this.$message({ type: 'warning', message: '请选择需要操作的资产' })
      return
    }
    this.$confirm('确认删除盘盈？', '提示').then(() => {
      this.$axios.post(this.$apis.assetScheduling.deleteInventoryAsset, {
        idList
      }).then(() => {
        this.loadData()
        this.loadAssets()
        this.$nextTick(() => {
          (this.$refs.table as any).clearSelection()
        })
      })
    })
  }

  inventoryAsset (id: any, inventoryState: any) {
    let idList: any = []
    if (!id) {
      idList = (this.$refs.table as any).selection.map((item: any) => item.id)
    } else {
      idList = [id]
    }
    if (!idList.length) {
      this.$message({ type: 'warning', message: '请选择需要操作的资产' })
      return
    }
    this.$axios.post(this.$apis.assetScheduling.updateInventoryAsset, {
      idList,
      inventoryState
    }).then(() => {
      this.loadData()
      this.loadAssets()
      this.$nextTick(() => {
        (this.$refs.table as any).clearSelection()
      })
    })
  }

  submit () {
    (this.$refs.form as ElForm).validate().then(() => {
      this.$axios.post(this.$apis.assetScheduling.insertInventoryAsset, this.formData).then(() => {
        this.dialogVisible = false
        this.loadData()
        this.loadAssets()
      })
    })
  }
}
